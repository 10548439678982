#nav {
	font-size: 1.2em;
	gap: 1em;
	grid-area: nav;
	max-height: var(--nav-height, 4rem);
	overflow: auto;
	top: env(titlebar-area-height, 0);
}

#nav > .nav-btn {
	background-color: transparent;
	color: inherit;
	border: none;
}

#install-btn > svg {
	margin-bottom: 10px;
}

@media (max-width: 500px) {
	#nav {
		gap: 16px;
		justify-content: space-between;
		font-size: 1.7em;
	}

	#nav .nav-link-label, #nav .nav-item-br {
		display: none;
	}
}
