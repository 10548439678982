#main {
	--link-color: #049ccf;
	--link-decoration: underline;
	grid-area: main;
	overflow: auto;
}

#main :target {
	scroll-margin-top: calc(var(--nav-height, 4rem) + 10px);
	scroll-margin-top: calc(var(--nav-height, 4rem) + env(titlebar-area-height) + 10px);
}

#main .categories-section .directory-label {
	padding: 18px 4rem;
	font-size: 20px;
}

.business-listing {
	line-height: 1.4;
}

#page-list {
	padding: 0.6rem;
	gap: 8px;
	grid-template-columns: repeat(auto-fit, minmax(350px, 400px));
	justify-items: center;
}

#page-list a {
	max-width: 350px;
	grow: 1;
}

article p:first-letter {
	margin-left: 2em;
}

.meta-author-links.flex {
	gap: 12px;
}

.category-link {
	text-decoration: 0px transparent none;
}

.category-description {
	border: none;
	padding: none;
}

.grid.directory {
	grid-template-columns: repeat(auto-fill, minmax(350px, 400px));
	grid-template-rows: masonry;
	gap: 8px;
	justify-content: space-evenly;
}

.share-business-btn {
	max-width: 250px;
}

.business-hours .hours-label {
	background-color: var(--accent-color);
	color: var(--alt-color);
	padding: 0.3em 0.7em;
	border-radius: 6px;
}
