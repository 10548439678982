@import url("./header.css");
@import url("./nav.css");
@import url("./sidebar.css");
@import url("./main.css");
@import url("./footer.css");

body {
	display: grid;
	grid-template-areas: ". . ." "nav nav nav" ". main sidebar" "footer footer footer";
	grid-template-rows: 0 var(--nav-height, 4rem) auto auto;
	grid-template-rows: env(titlebar-area-height, 0) var(--nav-height, 4rem) auto auto;
	grid-template-columns: 12px 1fr 400px;
	column-gap: 8px;
}

@media (max-width: 980px) {
	body {
		grid-template-areas: "nav" "main" "sidebar" "footer";
		grid-template-columns: 100%;
		grid-template-rows: var(--nav-height, 4rem) 1fr auto auto;
		gap: 8px 0;
	}
}

dialog {
	max-height: var(--dialog-max-height, 90vh);
	max-width: var(--dialog-max-width, 90vw);
	overflow: auto;
}

body > button.btn[is="share-button"] {
	bottom: 1rem;
	right: 1rem;
	border-radius: 50%;
	padding: 0.8rem;
	font-size: 1.5rem;
}

.business-listing [itemprop="image"] {
	width: auto;
	max-width: auto;
	height: 280px;
	object-fit: contain;
	object-position: center;
	border-radius: 6px;
}

.business-listing .contact-links {
	line-height: 1.4;
}

#search-btn {
	bottom: 1rem;
	left: 1rem;
	border-radius: 50%;
	padding: 0.8rem;
	font-size: 1.5rem;
}

@media (max-width: 800px) {
	.mobile-hidden {
		display: none;
	}
}

@media (min-width: 800px) {
	body.grid {
		grid-column-gap: 1.5rem;
	}
}
