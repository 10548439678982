#sidebar {
	grid-area: sidebar;
	--ad-block-width: 30vw;
	--ad-block-stack-width: var(--ad-block-width);
	--ad-block-text-width: var(--ad-block-width);
	--ad-block-image-width: var(--ad-block-width);
	grid-area: sidebar;
}

@media (max-width: 800px) {
	#sidebar {
		--ad-block-width: 95vw;
	}
}

#sidebar .sidebar-section {
	margin-bottom: 0.6em;
}

details.accordion[open] > :not(summary) {
	animation: slideInDown 200ms ease-in;
}

krv-events {
	width: 100%;
	min-height: 300px;
	max-height: 70vh;
	padding: 4px 12px;
}
