window-controls {
	background-color: var(--accent-color);
	height: 0;
	height: env(titelbar-area-height, 0);
	z-index: 5;
}

window-controls::part(container) {
	background-color: var(--accent-color);
	align-items: center;
}

window-controls form {
	display: inline-flex;
	height: env(titelbar-area-height, 0);
	width: 100%;
	padding-left: 8px;
	contain: strict;
	flex-direction: row;
	flex-wrap: no-wrap;
	align-items: center;
	gap: 8px;
}

window-controls > img, window-controls > b {
	-webkit-app-region: drag;
	app-region: drag;
}

window-controls .input {
	height: env(titlebar-area-height, 33px);
	margin: 0;
	width: cacl(0.5 * env(titlebar-area-width, 100%));
	background-color: var(--primary-color);
	color: var(--default-color);
	border: 1px solid #dadada;
	border-radius: 8px;
}
